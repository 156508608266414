const LayoutBlock = `
  position: relative;
  // z-index: 1;
  > div {
    width: 78em;
    max-width: 100%;
    margin: 0 auto;
    padding-right: 3em;
    padding-left: 3em;
    @media (max-width: 640px) {
      padding-right: 1.8em;
      padding-left: 1.8em;
    }
  }
  > div > img {
    max-width: 100%;
  }
`;

export default LayoutBlock;
