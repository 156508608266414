export default {
  white: "#FFFFFF",
  black: "#000000",
  transparent: "rgba(255,255,255,.6)",
  ultra_dark: "#313442",
  darker: "#5D617A", // to be removed
  dark: "#676B7C",
  grey: "#A3A5B1",
  light: "#D6D6DA",
  lighter: "#F2F4F7",
  lightest: "#F8F9FB",
  accent1: "#3858F5",
  accent1_light: "#9ebcff",
  accent2: "#D01F19",
  accent3: "#0F8CFF",
  coupon: "hsl(108, 80%, 70%)",
  specialCoupon: "hsl(25, 100%, 60%)",
  specialCouponAccent: "hsl(45, 100%, 70%)",
  unreadit: "#EF1B1B",
  accent1_gradient: "233deg, #7D2EFF 10%, #1435DC 90%",
  accent1_gradient_dark: "233deg, #102174 10%, #692EFF 90%",
  accent2_gradient: "233deg, #EA4E1C 10%, #D0192F 90%",
  black_gradient: "-140deg, #000000 0%, #222222 90%" // to be removed
};

// Alpha:
// 0% = #00
// 10% = #16
// 20% = #32
// 30% = #48
// 40% = #64
// 50% = #80
// 60% = #96
// 70% = #112
// 80% = #128
// 90% = #144
