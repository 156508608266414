import styled from "styled-components";
import colors from "../style/colors";

const P2 = styled.p`
  font-size: 1.1em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.dark)};
  margin: 0.1em 0 0.6em;
  font-weight: 500;
  line-height: 1.5;
  flex: 1;
`;

export default P2;
