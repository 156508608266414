import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import LayoutSection from "./layout/LayoutSection";
import colors from "./style/colors";
import P2 from "./typography/P2";

const Wrapper = styled.div`
  text-align: center;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin: 0.5em 1em;
  p {
    margin: 0;
  }
  &.active {
    text-decoration: underline;
    text-decoration-color: ${colors.dark};
  }
  :hover {
    text-decoration: underline;
    text-decoration-color: ${colors.dark};
  }
`;
const StyledA = styled.a`
  display: inline-block;
  text-decoration: none;
  margin: 0.5em 1em;
  p {
    margin: 0;
  }
  &.active {
    text-decoration: underline;
    text-decoration-color: ${colors.dark};
  }
  :hover {
    text-decoration: underline;
    text-decoration-color: ${colors.dark};
  }
`;

const Links = [
  {
    title: "Support",
    href: "https://www.notion.so/Boxy-Suite-Support-5ee9168b0b81463a8c5e5f2a8e565225"
  },
  {
    title: "Pricing",
    to: "/pricing"
  },
  {
    title: "Privacy",
    to: "/privacy-policy"
  },
  {
    title: "Terms",
    to: "/terms"
  },
  {
    title: "Updates",
    to: "/updates"
  },
  {
    title: "Press Kit",
    to: "/press"
  },
  {
    title: "Affiliate",
    to: "https://paddlemarketltd.formstack.com/forms/affiliate_signup?seller_id=28827"
  }
];

const FooterLinks = [
  {
    title: "Boxysuite Vs Outlook",
    href: "https://blog.boxysuite.com/boxysuite-vs-outlook/"
  },
  {
    title: "Boxysuite Vs Mailplane",
    to: "https://blog.boxysuite.com/boxysuite-vs-mailplane/"
  },
  {
    title: "Boxysuite Vs Superhuman",
    to: "https://blog.boxysuite.com/boxysuite-vs-superhuman/"
  },
  {
    title: "Boxysuite Vs Apple Mail",
    to: "https://blog.boxysuite.com/boxysuite-vs-apple-mail/"
  },
  {
    title: "Boxysuite Vs Kiwi",
    to: "https://blog.boxysuite.com/boxysuite-vs-kiwi-for-gmail/"
  }
];

const Footer = props => {
  const { legal } = props;

  return (
    <LayoutSection style={{ flex: "0 1 auto" }}>
      <Wrapper>
        {Links.map(link => {
          if (link.to) {
            return (
              <StyledLink key={link.to} to={link.to} activeClassName="active" partiallyActive={true}>
                <P2>{link.title}</P2>
              </StyledLink>
            );
          }
          if (link.href) {
            return (
              <StyledA key={link.href} href={link.href} target="_blank">
                <P2>{link.title}</P2>
              </StyledA>
            );
          }
        })}
      </Wrapper>
      <Wrapper>
        {FooterLinks.map(link => {
          if (link.to) {
            return (
              <StyledLink key={link.to} to={link.to} activeClassName="active" partiallyActive={true}>
                <P2>{link.title}</P2>
              </StyledLink>
            );
          }
          if (link.href) {
            return (
              <StyledA key={link.href} href={link.href} target="_blank">
                <P2>{link.title}</P2>
              </StyledA>
            );
          }
        })}
      </Wrapper>
      {legal && (
        <>
          <br />
          <P2 center color="grey">
            Boxy Suite is neither affiliated with, associated to, nor endorsed by Google in any way. © 2022 Google Inc.
            All rights reserved. Google, Gmail, Google Calendar, Inbox by Gmail and the Google Logo are registered
            trademarks of Google Inc.
          </P2>
        </>
      )}
      {/* {legal && (
        <>
          <br />
          <P2 center>
            Superlinear S.r.l. © 2019 - Via Paolo da Cannobio, 9, 20122 Milano
            (MI), Italy - VAT IT10540450961, REA number MI - 2539351,
            contributed capital 2,500€
          </P2>
        </>
      )} */}
      {/*<div className='prefinery-form-embed'></div>*/}
    </LayoutSection>
  );
};

export default Footer;
