import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, keywords, title, card, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  let metaTitle = `${title} | ${site.siteMetadata.title}`;

  if (title == "") {
    metaTitle = site.siteMetadata.title;
  }

  if (title.includes("Boxy Suite")) {
    metaTitle = title;
  }

  const metaDescription = description || site.siteMetadata.description;

  let helmetCanonical = {};

  if (canonical) {
    helmetCanonical = {
      rel: "canonical",
      href: canonical
    };
  }

  const metaCard = card || "https://boxysuite.com/card.jpg";

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      link={[helmetCanonical]}
      meta={[
        {
          name: "description",
          content: metaDescription
        },
        {
          property: "og:title",
          content: metaTitle
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: metaCard
        },
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author
        },
        {
          name: "twitter:title",
          content: metaTitle
        },
        {
          name: "twitter:description",
          content: metaDescription
        },
        {
          name: "twitter:image",
          content: metaCard
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: "keywords",
                content: keywords.join(", ")
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
  description: ""
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
