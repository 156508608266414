import { Link } from "gatsby";
import React, { useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import styled, { css } from "styled-components";
import Logo from "../../assets/logotype.svg";
import LayoutBlock from "./layout/LayoutBlock";
import colors from "./style/colors";
import P2 from "./typography/P2";
import Image from "./image";

const HeaderWrapper = styled.div`
  ${LayoutBlock}
  position: relative;
  &.dark {
    background: linear-gradient(${colors.accent1_gradient});
  }
  > div > div > * > p {
    color: ${colors.lighter};
    opacity: 0.8;
  }
  a.active {
    p {
      color: white;
      opacity: 1;
    }
    border-bottom-color: white;
  }
  > div {
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red;
    > * {
      border: 1px solid orange;
    } */
  }
`;

const BgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    opacity: 0.06;
    width: 38em;
    path {
      fill: ${colors.lighter};
    }
  }
`;

const LogoLink = styled(Link)`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.2em;
  .gatsby-image-wrapper {
    position: relative;
    width: 10em;
    height: auto;
  }
`;

const StyledLink = styled(Link)`
  p {
    color: white;
  }
  display: inline-block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5em;
  @media (max-width: 640px) {
    margin: 0 0.8em;
  }
  :last-child {
    margin-right: 0;
  }
  &.active {
    /* border-bottom: 2px solid ${colors.accent1}; */
  }
`;

const StyledA = styled.a`
  p {
    color: white;
  }
  display: inline-block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5em;
  @media (max-width: 640px) {
    margin: 0 0.8em;
  }
  :last-child {
    margin-right: 0;
  }
`;

const StyledSpan = styled.span`
  p {
    color: white;
  }
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5em;
  @media (max-width: 640px) {
    margin: 0 0.8em;
  }
  :last-child {
    margin-right: 0;
  }
`;

const HamburgerLink = styled(Link)`
  p {
    font-size: 1.4em;
    color: white;
    text-align: right;
  }
  display: inline-block;
  text-decoration: none;
  text-align: right;
  margin: 0.6em 0.5em;
  &.active {
    border-bottom: 2px solid ${colors.accent1};
  }
`;

const HamburgerA = styled.a`
  p {
    font-size: 1.4em;
    color: white;
    text-align: right;
  }
  display: inline-block;
  text-decoration: none;
  text-align: right;
  margin: 0.6em 0.5em;
`;

const NormalLinks = styled.div`
  flex: 0 0 auto;
  display: flex;
  p {
    text-align: center;
    margin: 0;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

function linksAnimation() {
  let styles = "";

  for (let i = 0; i < 20; i += 1) {
    styles += `
      a:nth-child(${i}) {
        transform: translateY(-${i}em);
      }
     `;
  }

  return css`
    ${styles}
  `;
}
function linksTransition() {
  let styles = "";

  for (let i = 0; i < 20; i += 1) {
    styles += `
      a:nth-child(${i}) {
        transition: 0.${2 + i}s all cubic-bezier(0.3, 0, 0.2, 1);
      }
     `;
  }

  return css`
    ${styles}
  `;
}

const HamburgerLinks = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(30px);
  z-index: 10;
  padding: 4em 1.5em 1.5em;
  @media (min-width: 960px) {
    display: none;
  }
  transition: 0.3s all cubic-bezier(0.3, 0, 0.2, 1);
  a {
    float: right;
    clear: both;
  }
  ${linksTransition()}
  ${props =>
    !props.show &&
    `
    opacity: 0;
    backdrop-filter: blur(0px);
    pointer-events: none;
    ${linksAnimation()}
  `}
`;

const HamburgerMenuWrapper = styled.div`
  z-index: 99;
  cursor: pointer;
  @media (min-width: 960px) {
    display: none;
  }
`;

const Links = [
  {
    title: "Support",
    // to: "/blog",
    href: "https://www.notion.so/Boxy-Suite-Support-5ee9168b0b81463a8c5e5f2a8e565225"
  },
  {
    title: "Blog",
    // to: "/blog",
    href: "https://blog.boxysuite.com/"
  },
  {
    title: "Account",
    href: "https://account.boxysuite.com/"
  },
  {
    title: "ROI Calculator",
    href: "/roicalculator"
  },
  {
    title: "Try it for free",
    href: "/?tryit=true"
  }
];

const SiteHeader = props => {
  const { onTryForFreeClick, blog, dark, label } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  function handleClick() {
    setMenuOpen(!menuOpen);
  }

  return (
    <HeaderWrapper className={dark ? "dark" : ""}>
      <div style={{ width: "100%" }}>
        <LogoLink to="/">
          <Image filename="logos/logotype" alt="Boxy Suite 2" />
        </LogoLink>
        <NormalLinks>
          {Links.map(link => {
            if (link.to) {
              if (link.title === "Try it for free" && onTryForFreeClick) {
                return (
                  <StyledSpan
                    key={link.to}
                    activeClassName="active"
                    partiallyActive={true}
                    onClick={() => {
                      if (link.title === "Try it for free") onTryForFreeClick && onTryForFreeClick();
                    }}
                  >
                    <P2>{link.title}</P2>
                  </StyledSpan>
                );
              } else {
                return (
                  <StyledLink key={link.to} to={link.to} activeClassName="active" partiallyActive={true}>
                    <P2>{link.title}</P2>
                  </StyledLink>
                );
              }
            }
            if (link.href) {
              if (link.title === "Try it for free" && onTryForFreeClick) {
                return (
                  <StyledSpan
                    key={link.href}
                    activeClassName="active"
                    partiallyActive={true}
                    onClick={() => {
                      if (link.title === "Try it for free") onTryForFreeClick && onTryForFreeClick();
                    }}
                  >
                    <P2>{link.title}</P2>
                  </StyledSpan>
                );
              } else {
                return (
                  <StyledA key={link.href} href={link.href}>
                    <P2>{link.title}</P2>
                  </StyledA>
                );
              }
            }
          })}
        </NormalLinks>
        <HamburgerLinks show={menuOpen}>
          {Links.map(link => {
            if (link.to) {
              return (
                <HamburgerLink key={link.to} to={link.to} activeClassName="active" partiallyActive={true}>
                  <P2>{link.title}</P2>
                </HamburgerLink>
              );
            }
            if (link.href) {
              return (
                <HamburgerA key={link.href} href={link.href}>
                  <P2>{link.title}</P2>
                </HamburgerA>
              );
            }
          })}
        </HamburgerLinks>
        <HamburgerMenuWrapper>
          <HamburgerMenu
            isOpen={menuOpen}
            menuClicked={handleClick}
            width={18}
            height={15}
            strokeWidth={2}
            rotate={0}
            color="white"
            borderRadius={1}
            animationDuration={0.5}
          />
        </HamburgerMenuWrapper>
      </div>
    </HeaderWrapper>
  );
};

export default SiteHeader;
